import React from "react";
import { Layout } from "../../../ui/templates/layout";
import HomeBenefits from "../components/HomeBenefits";
import HomeDemo from "../components/HomeDemo";
import HomeFaq from "../components/HomeFaq";
import HomeFeatures from "../components/HomeFeatures";
import HomeHero from "../components/HomeHero";
import HomePricing from "../components/HomePricing";
import HomeTestimonials from "../components/HomeTestimonals";
import "../index.scss";

export default function Home() {
  return (
    <Layout>
      <div className="home-page">
        <HomeHero />
        <HomeDemo />
        <HomeFeatures />
        <HomeBenefits />
        {/* --- hide testimonials for now --- */}
        {/* <HomeTestimonials /> */}
        <HomePricing />
        <HomeFaq />
      </div>
    </Layout>
  );
}
