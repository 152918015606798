import React from "react";

export default function Logo() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      // width="183"
      height="24"
      viewBox="0 0 183 40"
      fill="none"
    >
      <path
        d="M85.8976 31.3438C89.1013 31.3438 91.6989 29.6987 92.7379 27.967L93.4738 30.8243H98.972V9.17802H92.3915V20.2176C92.3915 23.8542 90.5732 25.4993 88.4519 25.4993C86.3739 25.4993 84.9452 24.1572 84.9452 21.6463V9.17802H78.3647V22.9018C78.3647 28.5731 81.6117 31.3438 85.8976 31.3438Z"
        fill="currentColor"
      />
      <path
        d="M104.88 39.5261H111.461V28.703C112.5 30.045 114.578 31.3438 117.781 31.3438C122.76 31.3438 128.258 27.7938 128.258 20.0012C128.258 12.728 123.453 8.65851 118.171 8.65851C114.924 8.65851 112.283 10.2603 111.114 11.9055L110.378 9.17802H104.88V39.5261ZM116.439 25.6292C113.582 25.6292 111.331 23.4646 111.331 20.0012C111.331 16.5378 113.582 14.3731 116.482 14.3731C119.34 14.3731 121.634 16.581 121.634 20.0012C121.634 23.4213 119.383 25.6292 116.439 25.6292Z"
        fill="currentColor"
      />
      <path
        d="M132.907 6.45059H139.661V0.129876H132.907V6.45059ZM132.994 30.8243H139.574V9.17802H132.994V30.8243Z"
        fill="currentColor"
      />
      <path
        d="M146.377 30.8243H152.957V14.1567H157.806V9.17802H152.957V8.26888C152.957 6.66705 154.213 5.62803 156.031 5.62803C156.724 5.62803 157.46 5.75791 158.153 6.06096V0.476217C156.94 0.129876 155.728 0 154.559 0C149.667 0 146.377 2.77072 146.377 7.70607V9.17802H143.52V14.1567H146.377V30.8243Z"
        fill="currentColor"
      />
      <path
        d="M171.126 39.4828L182.512 9.17802H175.629L171.299 22.5554L166.494 9.17802H159.437L168.052 29.6554L164.416 39.4828H171.126Z"
        fill="currentColor"
      />
      <path
        d="M19.0054 13.9812L22.0359 13.3751V8.65619H10.9963C4.97864 8.65619 1.47195 12.5092 1.47195 17.2281C1.47195 19.5226 2.3378 21.5141 4.15609 22.8561C1.77499 24.4147 0 26.9689 0 30.3025C0 36.147 4.71889 40 11.0396 40C17.4036 40 22.1225 36.147 22.1225 30.3025C22.1225 27.0988 20.3475 24.0683 17.9231 22.8561C19.5249 21.947 20.5207 19.7824 20.5207 17.9641C20.5207 16.3623 19.9146 14.8903 19.0054 13.9812ZM10.9963 21.1677C9.00485 21.1677 7.5329 19.5659 7.5329 17.5312C7.5329 15.4964 9.09144 13.808 10.9963 13.808C12.9878 13.808 14.3298 15.4964 14.3298 17.5312C14.3298 19.5659 13.0744 21.1677 10.9963 21.1677ZM11.0396 34.4586C8.57193 34.4586 6.53718 32.8134 6.53718 30.2592C6.53718 27.575 8.65851 25.9732 11.0396 25.9732C13.4207 25.9732 15.542 27.575 15.542 30.2592C15.542 32.8134 13.5073 34.4586 11.0396 34.4586Z"
        fill="currentColor"
      />
      <path
        d="M26.7954 30.822H33.3759V20.0421C33.3759 16.6653 35.1941 15.1501 37.4454 15.1501C38.7441 15.1501 39.8264 15.4964 40.6923 16.0159L41.9911 9.30558C41.1685 8.95924 39.9563 8.65619 38.7441 8.65619C36.2765 8.65619 33.9387 9.7818 32.8563 12.0763L32.3368 9.1757H26.7954V30.822Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M64.9868 28.8642C67.9076 26.6702 69.7968 23.1772 69.7968 19.243C69.7968 15.3095 67.9083 11.8171 64.9885 9.62306C64.9887 9.62306 64.9888 9.62306 64.989 9.62306C70.3022 9.62306 74.6095 13.9303 74.6095 19.2436C74.6095 24.5569 70.3022 28.8642 64.989 28.8642C64.9882 28.8642 64.9875 28.8642 64.9868 28.8642ZM64.9868 28.8642C62.9769 30.374 60.4785 31.2687 57.7711 31.2687C51.1295 31.2687 45.7454 25.8846 45.7454 19.243C45.7454 12.6014 51.1295 7.21729 57.7711 7.21729C60.4792 7.21729 62.9783 8.11246 64.9885 9.62306C59.6754 9.6233 55.3684 13.9305 55.3684 19.2436C55.3684 24.5562 59.6745 28.863 64.9868 28.8642Z"
        fill="currentColor"
      />
    </svg>
  );
}
