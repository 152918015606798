import React from "react";

export default function Money() {
  return (
    <svg
      width="52"
      height="52"
      viewBox="0 0 52 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="52"
        height="52"
        rx="26"
        fill="currentColor"
        fillOpacity="0.16"
      />
      <path
        d="M32.6667 38.3333H19.3333C14.4667 38.3333 11.6667 35.5333 11.6667 30.6667V21.3333C11.6667 16.4667 14.4667 13.6667 19.3333 13.6667H32.6667C37.5333 13.6667 40.3333 16.4667 40.3333 21.3333V30.6667C40.3333 35.5333 37.5333 38.3333 32.6667 38.3333ZM19.3333 15.6667C15.52 15.6667 13.6667 17.52 13.6667 21.3333V30.6667C13.6667 34.48 15.52 36.3333 19.3333 36.3333H32.6667C36.48 36.3333 38.3333 34.48 38.3333 30.6667V21.3333C38.3333 17.52 36.48 15.6667 32.6667 15.6667H19.3333Z"
        fill="currentColor"
      />
      <path
        d="M26 31C23.24 31 21 28.76 21 26C21 23.24 23.24 21 26 21C28.76 21 31 23.24 31 26C31 28.76 28.76 31 26 31ZM26 23C24.3467 23 23 24.3467 23 26C23 27.6533 24.3467 29 26 29C27.6533 29 29 27.6533 29 26C29 24.3467 27.6533 23 26 23Z"
        fill="currentColor"
      />
      <path
        d="M17.3333 30.3333C16.7867 30.3333 16.3333 29.88 16.3333 29.3333V22.6667C16.3333 22.12 16.7867 21.6667 17.3333 21.6667C17.88 21.6667 18.3333 22.12 18.3333 22.6667V29.3333C18.3333 29.88 17.88 30.3333 17.3333 30.3333Z"
        fill="currentColor"
      />
      <path
        d="M34.6667 30.3333C34.12 30.3333 33.6667 29.88 33.6667 29.3333V22.6667C33.6667 22.12 34.12 21.6667 34.6667 21.6667C35.2133 21.6667 35.6667 22.12 35.6667 22.6667V29.3333C35.6667 29.88 35.2133 30.3333 34.6667 30.3333Z"
        fill="currentColor"
      />
    </svg>
  );
}
