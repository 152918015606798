import React from "react";

interface TextAnimateWordProps {
  children: string;
  variant?: "gradient";
  perWord?: boolean;
  className?: string;
}

export function TextAnimateWord({
  children,
  variant,
  perWord = false,
  className = "",
}: TextAnimateWordProps) {
  if (variant === "gradient") {
    return (
      <>
        {children.split(" ")?.map((word) => (
          <>
            <span className={`letter word app-gradient-text ${className}`}>
              {word}
            </span>{" "}
          </>
        ))}
      </>
    );
  }

  return (
    <>
      {children.split(" ")?.map((word) => (
        <>
          <span className={`${perWord ? "letter" : ""} word ${className}`}>
            {word.split("").map((letter) => (
              <span className={!perWord ? "letter" : ""}>{letter}</span>
            ))}
          </span>{" "}
        </>
      ))}
    </>
  );
}
