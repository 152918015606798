import React from "react";

export default function Instagram() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 0C4.47715 0 0 4.47715 0 10C0 15.5229 4.47715 20 10 20C15.5229 20 20 15.5229 20 10C20 4.47715 15.5229 0 10 0ZM7.8014 4.69893C8.37033 4.67304 8.55207 4.66671 10.0005 4.66671H9.99887C11.4478 4.66671 11.6289 4.67304 12.1978 4.69893C12.7656 4.72493 13.1533 4.81482 13.4934 4.94671C13.8445 5.08282 14.1411 5.26505 14.4378 5.56171C14.7345 5.85816 14.9167 6.15572 15.0534 6.5065C15.1845 6.8456 15.2745 7.2332 15.3011 7.80093C15.3267 8.36987 15.3334 8.5516 15.3334 10.0001C15.3334 11.4485 15.3267 11.6299 15.3011 12.1988C15.2745 12.7663 15.1845 13.154 15.0534 13.4933C14.9167 13.8439 14.7345 14.1415 14.4378 14.4379C14.1415 14.7346 13.8444 14.9173 13.4937 15.0535C13.1543 15.1854 12.7663 15.2753 12.1986 15.3013C11.6297 15.3271 11.4485 15.3335 9.99987 15.3335C8.55153 15.3335 8.36987 15.3271 7.801 15.3013C7.23333 15.2753 6.8456 15.1854 6.50629 15.0535C6.15574 14.9173 5.85818 14.7346 5.56184 14.4379C5.26528 14.1415 5.08306 13.8439 4.94673 13.4931C4.81495 13.154 4.72505 12.7665 4.69895 12.1987C4.67317 11.6298 4.66672 11.4485 4.66672 10.0001C4.66672 8.5516 4.67339 8.36973 4.69883 7.80087C4.72439 7.23327 4.81439 6.8456 4.94661 6.50639C5.08328 6.15572 5.26551 5.85816 5.56217 5.56171C5.85862 5.26515 6.15618 5.08293 6.50696 4.94671C6.84607 4.81482 7.23367 4.72493 7.8014 4.69893Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.52273 5.62829C9.6156 5.62814 9.7156 5.62819 9.8234 5.62823L10.0012 5.62829C11.4252 5.62829 11.594 5.63339 12.1563 5.65895C12.6763 5.68273 12.9585 5.76962 13.1465 5.84262C13.3954 5.93929 13.5729 6.05485 13.7595 6.24151C13.9461 6.42818 14.0617 6.60596 14.1585 6.85482C14.2315 7.04262 14.3185 7.32489 14.3422 7.84489C14.3678 8.40709 14.3733 8.57595 14.3733 9.99935C14.3733 11.4227 14.3678 11.5916 14.3422 12.1538C14.3185 12.6738 14.2315 12.956 14.1585 13.1438C14.0619 13.3927 13.9461 13.57 13.7595 13.7565C13.5728 13.9432 13.3955 14.0587 13.1465 14.1554C12.9587 14.2287 12.6763 14.3154 12.1563 14.3392C11.5941 14.3647 11.4252 14.3703 10.0012 14.3703C8.57707 14.3703 8.40827 14.3647 7.84607 14.3392C7.32607 14.3152 7.0438 14.2283 6.85567 14.1553C6.60681 14.0586 6.42903 13.943 6.24236 13.7564C6.05569 13.5697 5.94013 13.3924 5.84324 13.1434C5.77024 12.9556 5.68324 12.6734 5.65957 12.1534C5.63402 11.5912 5.62891 11.4222 5.62891 9.99802C5.62891 8.57375 5.63402 8.40575 5.65957 7.84355C5.68335 7.32355 5.77024 7.04129 5.84324 6.85329C5.93991 6.60441 6.05569 6.42663 6.24236 6.23996C6.42903 6.05329 6.60681 5.93773 6.85567 5.84084C7.04367 5.76751 7.32607 5.68084 7.84607 5.65695C8.33807 5.63473 8.52873 5.62806 9.52273 5.62695V5.62829ZM12.8481 6.51385C12.4947 6.51385 12.2081 6.80022 12.2081 7.15362C12.2081 7.50695 12.4947 7.79362 12.8481 7.79362C13.2014 7.79362 13.4881 7.50695 13.4881 7.15362C13.4881 6.80029 13.2014 6.51363 12.8481 6.51363V6.51385ZM7.26233 10.0006C7.26233 8.48802 8.4886 7.26169 10.0011 7.26162C11.5137 7.26162 12.7397 8.48795 12.7397 10.0006C12.7397 11.5132 11.5138 12.7389 10.0012 12.7389C8.48867 12.7389 7.26233 11.5132 7.26233 10.0006Z"
        fill="black"
      />
      <path
        d="M10.0005 8.22266C10.9823 8.22266 11.7783 9.01852 11.7783 10.0005C11.7783 10.9823 10.9823 11.7783 10.0005 11.7783C9.01859 11.7783 8.22266 10.9823 8.22266 10.0005C8.22266 9.01852 9.01859 8.22266 10.0005 8.22266Z"
        fill="black"
      />
    </svg>
  );
}
