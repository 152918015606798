import React from "react";

export default function CloseIcon() {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.9324 12.8353L13.3258 23.4419C13.0842 23.6836 12.6836 23.6836 12.4419 23.4419C12.2004 23.2004 12.2003 22.7997 12.4419 22.5581L23.0485 11.9515C23.2902 11.7098 23.6909 11.7099 23.9324 11.9515C24.174 12.1931 24.174 12.5937 23.9324 12.8353Z"
        fill="#181818"
      />
      <path
        d="M23.1647 23.9324L12.5581 13.3258C12.3164 13.0842 12.3165 12.6835 12.5581 12.4419C12.7997 12.2003 13.2003 12.2003 13.4419 12.4419L24.0485 23.0485C24.2902 23.2902 24.2902 23.6908 24.0485 23.9324C23.807 24.174 23.4063 24.174 23.1647 23.9324Z"
        fill="#181818"
      />
      <path
        d="M35.5 18C35.5 8.33502 27.665 0.5 18 0.5C8.33502 0.5 0.5 8.33502 0.5 18C0.5 27.665 8.33502 35.5 18 35.5C27.665 35.5 35.5 27.665 35.5 18Z"
        stroke="#F8F8F8"
      />
    </svg>
  );
}
