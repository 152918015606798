import React from "react";
import { Helmet } from "react-helmet";
import Home from "../components/modules/home/pages/Home";
import { Script } from "gatsby";

const IndexPage = () => {
  return (
    <>
      <Helmet>
        <title>Groupify - Best way to run a paid community</title>
        <meta
          name="description"
          content="Groupify enables group creation on WhatsApp and Telegram, manages invites and members, collects payments so you can focus on creating great content and growing your community."
        />
        <meta
          property="og:title"
          content="Groupify - Best way to run a paid community"
        />
        <meta
          property="og:description"
          content="Groupify enables group creation on WhatsApp and Telegram, manages invites and members, collects payments so you can focus on creating great content and growing your community."
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:image"
          content="https://res.cloudinary.com/orinami/image/upload/v1661357765/groupify/groupify-social-media-banner.png"
        />
        <meta
          name="twitter:title"
          content="Groupify - Best way to run a paid community"
        />
        <meta
          name="twitter:description"
          content="Groupify enables group creation on WhatsApp and Telegram, manages invites and members, collects payments so you can focus on creating great content and growing your community."
        />
        <meta
          property="og:image"
          content="https://res.cloudinary.com/orinami/image/upload/v1661357765/groupify/groupify-social-media-banner.png"
        />
      </Helmet>

      <Script id="meta-pixel">
        {`!function(f,b,e,v,n,t,s)
      {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
      n.callMethod.apply(n,arguments):n.queue.push(arguments)};
      if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
      n.queue=[];t=b.createElement(e);t.async=!0;
      t.src=v;s=b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t,s)}(window, document,'script',
      'https://connect.facebook.net/en_US/fbevents.js');
      fbq('init', '1221535678509408');
      fbq('track', 'PageView');`}
      </Script>

      <noscript>
        <img
          height="1"
          width="1"
          className="hidden"
          src="https://www.facebook.com/tr?id=1221535678509408&ev=PageView&noscript=1"
        />
      </noscript>

      <Home />
    </>
  );
};

export default IndexPage;
