import { Link } from "gatsby";
import React from "react";
import { AnchorProps } from "./types";

export default function Anchor({
  children,
  to = "/",
  className = "",
}: AnchorProps) {
  const linkProps = {
    to,
    className,
  };

  return <Link {...linkProps}>{children}</Link>;
}
