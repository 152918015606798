import React from "react";

export default function Bank() {
  return (
    <svg
      width="52"
      height="52"
      viewBox="0 0 52 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="52" height="52" rx="26" fill="#4D486E" />
      <path
        d="M38 25.6668H14C12.72 25.6668 11.6667 24.6135 11.6667 23.3335V18.9068C11.6667 18.0002 12.2933 17.0802 13.1333 16.7468L25.1333 11.9469C25.64 11.7469 26.36 11.7469 26.8667 11.9469L38.8667 16.7468C39.7067 17.0802 40.3333 18.0135 40.3333 18.9068V23.3335C40.3333 24.6135 39.28 25.6668 38 25.6668ZM26 13.7869C25.9467 13.7869 25.8934 13.7868 25.8667 13.8001L13.88 18.6002C13.8 18.6402 13.6667 18.8135 13.6667 18.9068V23.3335C13.6667 23.5202 13.8133 23.6668 14 23.6668H38C38.1867 23.6668 38.3333 23.5202 38.3333 23.3335V18.9068C38.3333 18.8135 38.2134 18.6402 38.12 18.6002L26.12 13.8001C26.0934 13.7868 26.0533 13.7869 26 13.7869Z"
        fill="currentColor"
      />
      <path
        d="M39.3333 40.3333H12.6667C12.12 40.3333 11.6667 39.88 11.6667 39.3333V35.3333C11.6667 34.0533 12.72 33 14 33H38C39.28 33 40.3333 34.0533 40.3333 35.3333V39.3333C40.3333 39.88 39.88 40.3333 39.3333 40.3333ZM13.6667 38.3333H38.3333V35.3333C38.3333 35.1467 38.1867 35 38 35H14C13.8133 35 13.6667 35.1467 13.6667 35.3333V38.3333Z"
        fill="currentColor"
      />
      <path
        d="M15.3333 35C14.7866 35 14.3333 34.5467 14.3333 34V24.6667C14.3333 24.12 14.7866 23.6667 15.3333 23.6667C15.88 23.6667 16.3333 24.12 16.3333 24.6667V34C16.3333 34.5467 15.88 35 15.3333 35Z"
        fill="currentColor"
      />
      <path
        d="M20.6667 35C20.12 35 19.6667 34.5467 19.6667 34V24.6667C19.6667 24.12 20.12 23.6667 20.6667 23.6667C21.2134 23.6667 21.6667 24.12 21.6667 24.6667V34C21.6667 34.5467 21.2134 35 20.6667 35Z"
        fill="currentColor"
      />
      <path
        d="M26 35C25.4533 35 25 34.5467 25 34V24.6667C25 24.12 25.4533 23.6667 26 23.6667C26.5467 23.6667 27 24.12 27 24.6667V34C27 34.5467 26.5467 35 26 35Z"
        fill="currentColor"
      />
      <path
        d="M31.3333 35C30.7866 35 30.3333 34.5467 30.3333 34V24.6667C30.3333 24.12 30.7866 23.6667 31.3333 23.6667C31.88 23.6667 32.3333 24.12 32.3333 24.6667V34C32.3333 34.5467 31.88 35 31.3333 35Z"
        fill="currentColor"
      />
      <path
        d="M36.6667 35C36.12 35 35.6667 34.5467 35.6667 34V24.6667C35.6667 24.12 36.12 23.6667 36.6667 23.6667C37.2134 23.6667 37.6667 24.12 37.6667 24.6667V34C37.6667 34.5467 37.2134 35 36.6667 35Z"
        fill="currentColor"
      />
      <path
        d="M40.6667 40.3333H11.3333C10.7867 40.3333 10.3333 39.88 10.3333 39.3333C10.3333 38.7867 10.7867 38.3333 11.3333 38.3333H40.6667C41.2133 38.3333 41.6667 38.7867 41.6667 39.3333C41.6667 39.88 41.2133 40.3333 40.6667 40.3333Z"
        fill="currentColor"
      />
      <path
        d="M26 22.3333C24.3467 22.3333 23 20.9867 23 19.3333C23 17.68 24.3467 16.3333 26 16.3333C27.6533 16.3333 29 17.68 29 19.3333C29 20.9867 27.6533 22.3333 26 22.3333ZM26 18.3333C25.4533 18.3333 25 18.7867 25 19.3333C25 19.88 25.4533 20.3333 26 20.3333C26.5467 20.3333 27 19.88 27 19.3333C27 18.7867 26.5467 18.3333 26 18.3333Z"
        fill="currentColor"
      />
    </svg>
  );
}
