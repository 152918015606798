import React from "react";

export default function AddUser() {
  return (
    <svg
      width="52"
      height="52"
      viewBox="0 0 52 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="52" height="52" rx="26" fill="#66BB6B" />
      <path
        d="M25.2799 29.3065C22.6665 29.3065 20.5332 27.1731 20.5332 24.5598C20.5332 21.9465 22.6665 19.8131 25.2799 19.8131C27.8932 19.8131 30.0265 21.9465 30.0265 24.5598C30.0265 27.1731 27.8932 29.3065 25.2799 29.3065ZM25.2799 21.8265C23.7732 21.8265 22.5332 23.0532 22.5332 24.5732C22.5332 26.0932 23.7599 27.3198 25.2799 27.3198C26.7999 27.3198 28.0265 26.0932 28.0265 24.5732C28.0265 23.0532 26.7999 21.8265 25.2799 21.8265Z"
        fill="currentColor"
      />
      <path
        d="M32.2 37.933C31.6534 37.933 31.2 37.4797 31.2 36.933C31.2 34.373 28.5467 32.293 25.28 32.293C22.0134 32.293 19.36 34.373 19.36 36.933C19.36 37.4797 18.9067 37.933 18.36 37.933C17.8134 37.933 17.36 37.4797 17.36 36.933C17.36 33.2797 20.9067 30.293 25.28 30.293C29.6534 30.293 33.2 33.2664 33.2 36.933C33.2 37.4797 32.7467 37.933 32.2 37.933Z"
        fill="currentColor"
      />
      <path
        d="M25.3333 40.3333C17.8 40.3333 11.6667 34.2 11.6667 26.6667C11.6667 19.1333 17.8 13 25.3333 13C27.1867 13 28.9733 13.36 30.6533 14.0667C31.1333 14.2667 31.3733 14.8 31.2133 15.2933C31.0667 15.7333 31 16.2 31 16.6667C31 17.4533 31.2133 18.2266 31.6267 18.8933C31.84 19.2666 32.12 19.6 32.44 19.88C33.6 20.9333 35.32 21.2667 36.6667 20.7867C37.16 20.6 37.72 20.8533 37.92 21.3467C38.64 23.04 39 24.84 39 26.68C39 34.2 32.8667 40.3333 25.3333 40.3333ZM25.3333 15C18.9067 15 13.6667 20.2267 13.6667 26.6667C13.6667 33.1067 18.9067 38.3333 25.3333 38.3333C31.76 38.3333 37 33.1067 37 26.6667C37 25.3867 36.7867 24.12 36.3867 22.9067C34.5467 23.2267 32.5333 22.6534 31.12 21.36C30.6533 20.96 30.24 20.4667 29.92 19.92C29.3333 18.96 29.0133 17.8267 29.0133 16.6667C29.0133 16.3067 29.04 15.96 29.1067 15.6134C27.8933 15.2 26.6267 15 25.3333 15Z"
        fill="currentColor"
      />
      <path
        d="M35.3333 23C33.76 23 32.2667 22.4134 31.1067 21.36C30.64 20.96 30.2267 20.4667 29.9067 19.92C29.32 18.96 29 17.8267 29 16.6667C29 15.9867 29.1067 15.32 29.32 14.68C29.6133 13.7734 30.12 12.9333 30.8 12.2533C32 11.0267 33.6133 10.3333 35.3467 10.3333C37.16 10.3333 38.88 11.1067 40.0533 12.44C41.0933 13.6 41.68 15.0933 41.68 16.6667C41.68 17.1733 41.6133 17.68 41.48 18.16C41.3467 18.76 41.0933 19.3867 40.7467 19.9334C39.9733 21.24 38.7467 22.2133 37.3067 22.6667C36.7067 22.8933 36.04 23 35.3333 23ZM35.3333 12.3333C34.1467 12.3333 33.04 12.8 32.2267 13.64C31.76 14.12 31.4267 14.6666 31.2267 15.2933C31.08 15.7333 31.0133 16.2 31.0133 16.6667C31.0133 17.4533 31.2267 18.2267 31.64 18.8933C31.8533 19.2667 32.1333 19.6 32.4533 19.88C33.6133 20.9333 35.3333 21.2667 36.68 20.7867C37.6933 20.4667 38.52 19.8 39.0533 18.9067C39.2933 18.52 39.4533 18.1067 39.5467 17.6934C39.64 17.3467 39.68 17.0133 39.68 16.6667C39.68 15.6 39.28 14.5734 38.56 13.7734C37.7467 12.8534 36.5733 12.3333 35.3333 12.3333Z"
        fill="currentColor"
      />
      <path
        d="M37.32 17.64H33.3333C32.7867 17.64 32.3333 17.1867 32.3333 16.64C32.3333 16.0933 32.7867 15.64 33.3333 15.64H37.32C37.8667 15.64 38.32 16.0933 38.32 16.64C38.32 17.1867 37.88 17.64 37.32 17.64Z"
        fill="currentColor"
      />
      <path
        d="M35.3333 19.68C34.7867 19.68 34.3333 19.2267 34.3333 18.68V14.6934C34.3333 14.1467 34.7867 13.6934 35.3333 13.6934C35.88 13.6934 36.3333 14.1467 36.3333 14.6934V18.68C36.3333 19.24 35.88 19.68 35.3333 19.68Z"
        fill="currentColor"
      />
    </svg>
  );
}
