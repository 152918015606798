import React from "react";
import { BUTTON_COMPONENT_TO_VARIANT_MAPPING } from "../../../../constants/button";
import "./index.scss";
import PrimaryButton from "./Primary";

interface ButtonProps {
  content?: string | React.ReactNode;
  variant?: "primary" | "outlined" | "plain";
  className?: string;
  onClick?: () => void;
}

export default function Button({
  content,
  variant = "primary",
  className = "",
  onClick,
}: ButtonProps) {
  const ButtonComponent = BUTTON_COMPONENT_TO_VARIANT_MAPPING[variant]
    ? BUTTON_COMPONENT_TO_VARIANT_MAPPING[variant]
    : PrimaryButton;

  return (
    <ButtonComponent
      onClick={onClick}
      className={className}
      content={content || ""}
    />
  );
}
