import React from "react";
import { Footer } from "../../organisms/footer";
import { Navbar } from "../../organisms/navbar";
import { LayoutProps } from "./types";

export default function Layout({ children }: LayoutProps) {
  return (
    <>
      <Navbar />
      {children}
      <Footer />
    </>
  );
}
