import React from "react";

export default function Twitter() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 0C4.47715 0 0 4.47715 0 10C0 15.5229 4.47715 20 10 20C15.5229 20 20 15.5229 20 10C20 4.47715 15.5229 0 10 0ZM9.9618 8.7834L9.9382 8.39413C9.8674 7.3852 10.4891 6.46365 11.4727 6.10615C11.8347 5.97905 12.4485 5.96315 12.8498 6.07438C13.0072 6.12205 13.3062 6.28093 13.5187 6.42393L13.9043 6.68607L14.3292 6.55104C14.5653 6.47954 14.8801 6.36037 15.0217 6.28093C15.1555 6.20943 15.2735 6.16971 15.2735 6.19354C15.2735 6.3286 14.9823 6.7894 14.7384 7.0436C14.4079 7.40107 14.5023 7.43287 15.1712 7.19453C15.5725 7.05947 15.5804 7.05947 15.5017 7.2104C15.4545 7.28987 15.2105 7.56793 14.9509 7.82213C14.5102 8.25907 14.4866 8.30673 14.4866 8.6722C14.4866 9.23627 14.219 10.412 13.9515 11.0555C13.4557 12.2631 12.3934 13.5103 11.3311 14.1379C9.83593 15.0198 7.845 15.2422 6.1689 14.7258C5.61019 14.5511 4.65015 14.1061 4.65015 14.0267C4.65015 14.0029 4.94131 13.9711 5.29543 13.9631C6.03512 13.9473 6.7748 13.7407 7.40433 13.3753L7.82927 13.1211L7.3414 12.9542C6.64891 12.7159 6.02725 12.1677 5.86987 11.6513C5.82265 11.4845 5.83839 11.4765 6.27907 11.4765L6.73547 11.4686L6.34989 11.2859C5.89348 11.0555 5.47641 10.6663 5.27181 10.269C5.1223 9.983 4.93345 9.26007 4.98853 9.20447C5.00427 9.18067 5.16952 9.22833 5.35838 9.29187C5.90135 9.49047 5.97217 9.4428 5.65741 9.10913C5.06722 8.50533 4.88623 7.60767 5.16952 6.7576L5.30329 6.37627L5.82265 6.89267C6.885 7.93333 8.1362 8.553 9.5684 8.73573L9.9618 8.7834Z"
        fill="black"
      />
    </svg>
  );
}
