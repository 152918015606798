import React from "react";

export default function Arrow() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.0251 15.6834C11.8668 15.6834 11.7084 15.6251 11.5834 15.5001C11.3418 15.2584 11.3418 14.8584 11.5834 14.6168L16.2001 10.0001L11.5834 5.38343C11.3418 5.14176 11.3418 4.74176 11.5834 4.5001C11.8251 4.25843 12.2251 4.25843 12.4668 4.5001L17.5251 9.55843C17.7668 9.8001 17.7668 10.2001 17.5251 10.4418L12.4668 15.5001C12.3418 15.6251 12.1834 15.6834 12.0251 15.6834Z"
        fill="#5C28AE"
      />
      <path
        d="M16.9417 10.625H2.91666C2.57499 10.625 2.29166 10.3417 2.29166 10C2.29166 9.65833 2.57499 9.375 2.91666 9.375H16.9417C17.2833 9.375 17.5667 9.65833 17.5667 10C17.5667 10.3417 17.2833 10.625 16.9417 10.625Z"
        fill="#5C28AE"
      />
    </svg>
  );
}
