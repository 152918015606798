import React from "react";

export default function User() {
  return (
    <svg
      width="52"
      height="52"
      viewBox="0 0 52 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="52" height="52" rx="26" fill="#DD7972" />
      <path
        d="M26 27C21.7733 27 18.3333 23.56 18.3333 19.3334C18.3333 15.1067 21.7733 11.6667 26 11.6667C30.2267 11.6667 33.6667 15.1067 33.6667 19.3334C33.6667 23.56 30.2267 27 26 27ZM26 13.6667C22.88 13.6667 20.3333 16.2134 20.3333 19.3334C20.3333 22.4534 22.88 25 26 25C29.12 25 31.6667 22.4534 31.6667 19.3334C31.6667 16.2134 29.12 13.6667 26 13.6667Z"
        fill="currentColor"
      />
      <path
        d="M37.4535 40.3333C36.9069 40.3333 36.4535 39.88 36.4535 39.3333C36.4535 34.7333 31.7602 31 26.0002 31C20.2402 31 15.5469 34.7333 15.5469 39.3333C15.5469 39.88 15.0935 40.3333 14.5469 40.3333C14.0002 40.3333 13.5469 39.88 13.5469 39.3333C13.5469 33.64 19.1335 29 26.0002 29C32.8669 29 38.4535 33.64 38.4535 39.3333C38.4535 39.88 38.0002 40.3333 37.4535 40.3333Z"
        fill="currentColor"
      />
    </svg>
  );
}
