import React, { useRef } from "react";
import { TextAnimateProps } from "./types";
import { CustomWaypoint } from "../../../ui/atoms/customWaypoint";
import { useTextAnimate } from "../../../../hooks/useTextAnimate";

// this component helps to stagger fade in animation on texts

export default function TextAnimate({ children, id }: TextAnimateProps) {
  const textAnimateRef = useRef() as React.MutableRefObject<HTMLInputElement>;
  const [triggerAnimation] = useTextAnimate({ id });

  return (
    <CustomWaypoint onEnter={triggerAnimation}>
      <div>
        {/* --- FOR SEO --- */}
        <span className="app-text-animate--hidden">{children}</span>

        <span className="app-text-animate" id={id} ref={textAnimateRef}>
          {children}
        </span>
      </div>
    </CustomWaypoint>
  );
}
