import React from "react";

export default function MenuIcon() {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25.5 14.4583H10.5C10.1583 14.4583 9.875 14.175 9.875 13.8333C9.875 13.4917 10.1583 13.2083 10.5 13.2083H25.5C25.8417 13.2083 26.125 13.4917 26.125 13.8333C26.125 14.175 25.8417 14.4583 25.5 14.4583Z"
        fill="#181818"
      />
      <path
        d="M25.5 18.625H10.5C10.1583 18.625 9.875 18.3417 9.875 18C9.875 17.6583 10.1583 17.375 10.5 17.375H25.5C25.8417 17.375 26.125 17.6583 26.125 18C26.125 18.3417 25.8417 18.625 25.5 18.625Z"
        fill="#181818"
      />
      <path
        d="M25.5 22.7917H10.5C10.1583 22.7917 9.875 22.5083 9.875 22.1667C9.875 21.825 10.1583 21.5417 10.5 21.5417H25.5C25.8417 21.5417 26.125 21.825 26.125 22.1667C26.125 22.5083 25.8417 22.7917 25.5 22.7917Z"
        fill="#181818"
      />
      <rect x="0.5" y="0.5" width="35" height="35" rx="17.5" stroke="#F8F8F8" />
    </svg>
  );
}
